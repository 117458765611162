<template>
  <div>
    <div class="Cart_Header">
      <h1>
        <b>购物车</b>
        <span
          v-if="cart_list && cart_list.length != 0"
          @click="cart_state = !cart_state"
          >{{ cart_state ? "管理" : "取消" }}</span
        >
      </h1>
      <p>共 {{ cart_list.length }} 件宝贝</p>
    </div>
    <noData text="暂无数据" v-if="cart_list && cart_list.length == 0"></noData>
    <ul class="Cart_Ul" v-else>
      <h2>
        <van-checkbox
          v-model="allChecked"
          checked-color="#fb5839"
          @click="setAllCheck"
        ></van-checkbox>
        <b>十九匠商城</b>
        <span @click="$router.push({ name: 'couponCentre' })">领券</span>
      </h2>
      <van-checkbox-group
        v-model="checkResult"
        checked-color="#fb5839"
        ref="checkboxGroup"
      >
        <li
          class="Cart_Li"
          v-for="(item, index) in cart_list"
          :key="item.cart_id"
        >
          <div v-if="item.ifcartmarketing" class="xs_tips">
            <p class="xs_t-p">
              该商品限时下单可享减免{{ item.cart_discounts_price }}元优惠
            </p>
            <!-- <span>{{ item.discounts_end_time | time }}</span> -->
          </div>

          <div class="flex-container">
            <van-checkbox
              @click="setRadioCheck"
              :name="`${item.cart_id}`"
              :disabled="item.goods_state == 0 && !cart_state == false"
              :bind-group="
                !cart_state == false && item.goods_state == 0 ? false : true
              "
            ></van-checkbox>
            <div
              class="flex-container flex1"
              @click="
                item.goods_state
                  ? $router.push({
                      name: 'HomeGoodsdetail',
                      query: { goods_id: item.goods_id },
                    })
                  : ''
              "
            >
              <img :src="item.goodsimage_full_url" class="Cart_image" />
              <div class="Cart_product flex1">
                <h3>
                  {{ item.bl_desc ? item.bl_desc : item.goods_name }}
                </h3>
                <div class="sku">
                  <div class="sku_i">
                    <i v-if="item.bl_id > 0">优惠套餐</i>
                    <i v-else>{{ item.goods_spec.spvalue_name }}</i>
                  </div>
                  <template>
                    <i v-if="item.mgdiscount_desc" class="sku_tip">{{
                      item.mgdiscount_desc
                    }}</i>
                    <i v-if="item.qianggou_desc" class="sku_tip">{{
                      item.qianggou_desc
                    }}</i>
                    <i v-if="item.xianshi_desc" class="sku_tip">{{
                      item.xianshi_desc
                    }}</i>
                  </template>
                </div>
                <div class="data" v-if="item.goods_state">
                  <p class="price">
                    <i>￥</i>
                    {{ item.goods_price }}
                  </p>

                  <div class="action flex-container flex1" v-if="cart_state">
                    <template>
                      <img
                        src="@image/shop/shop_action_subtract.png"
                        @click.stop="addChcek(index, 'sub')"
                      />
                      <b>{{ item.buy_num }}</b>
                    </template>
                    <template>
                      <img
                        src="@image/shop/shop_action_add.png"
                        @click.stop="addChcek(index, 'add')"
                      />
                    </template>
                  </div>
                </div>
                <div class="data" v-else="item.goods_state">
                  {{ item.goods_msg }}
                </div>
                <div class="data" v-if="item.point">
                  <p class="price">+{{ item.point }} 积分</p>
                </div>
              </div>
            </div>
          </div>
          <oActiveTip :g="item"></oActiveTip>
          <div v-if="item.ifcartmarketing" class="xs-time">
            <oTime
              :times="item.cart_discounts_end_time * 1000"
              tips="距离结束还有："
            ></oTime>
          </div>
          <oCombination
            :combination="item.bl_goods_list"
            :len="item.buy_num"
            v-if="item.bl_goods_list"
          ></oCombination>
          <oGift :gift="item.gift_list" v-if="item.gift_list"></oGift>
        </li>
      </van-checkbox-group>
    </ul>

    <div
      class="Cart_Fixed flex-container"
      v-if="cart_list && cart_list.length != 0"
    >
      <van-checkbox
        class="check"
        v-model="allChecked"
        checked-color="#fb5839"
        @click="setAllCheck"
        >全选</van-checkbox
      >
      <div class="data flex1">
        <template v-if="cart_state">
          <span>合计: </span>
          <i>￥</i>
          <b>{{ checkPrice }}</b>
        </template>
      </div>
      <div class="Cart_Submit" @click="onSubmit">
        {{ cart_state ? "结算" : "删除" }}({{ checkResult.length }})
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup, Toast, Dialog } from "vant";
import oTime from "@/components/otime/index.vue";
import oGift from "@view/me/view/buy/components/product/components/gift/";
import oCombination from "@view/me/view/buy/components/product/components/combination/";
import oActiveTip from "@view/me/view/buy/components/product/components/activeTip/";
import noData from "@/components/noData/";
import { getCartDel, getCartAdd, getCartList, getCartQuantity } from "./js/";
export default {
  name: "cart",
  components: {
    vanCheckbox: Checkbox,
    vanCheckboxGroup: CheckboxGroup,
    oTime,
    oCombination,
    oActiveTip,
    oGift,
    noData,
  },
  data() {
    return {
      checkResult: [], //选择的产品
      cart_list: [], //购物车列表
      cart_state: true, //管理删除按钮

      checkPrice: 0, // 价格
      cart_id: "", // 购物车ID 提交用
    };
  },
  created() {
    this.getCartList();
  },
  watch: {
    cart_state() {
      this.$refs.checkboxGroup.toggleAll(false);
      this.allChecked = false;
    },
  },
  methods: {
    getFindCartChcekData() {
      //合计
      let price = 0,
        num = "";

      this.checkResult.map((item, i) => {
        for (let index = 0; index < this.cart_list.length; index++) {
          let list = this.cart_list[index];

          if (list.cart_id == item) {
            price += parseFloat(list.goods_price) * list.buy_num;
            num += `${item}|${list.buy_num}${list.point != 0 ? "|1" : "|0"},`;
            return list;
          }
        }
      });
      this.cart_id = num;
      this.checkPrice = price.toFixed(2);
    },

    setRadioCheck() {
      //单选
      this.getFindCartChcekData();
      let disables = 0;

      this.cart_list.map((item) => {
        if (item.goods_state == 0) {
          disables += 1;
          return item;
        }
      });

      if (this.checkResult.length != this.cart_list.length - disables) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
    },
    setAllCheck() {
      //全选反选
      if (this.allChecked) {
        this.$refs.checkboxGroup.toggleAll({
          checked: true,
          skipDisabled: true,
        });
      } else {
        this.$refs.checkboxGroup.toggleAll(false);
      }
      this.getFindCartChcekData();
    },
    async addChcek(index, sub) {
      let l = this.cart_list[index];

      if (sub == "add") {
        let n = l.buy_num;
        let data = await getCartQuantity(l.cart_id, ++n);

        if (data.code == 10000) {
          l.buy_num = data.result;
          Toast("成功");
        } else {
          Toast(data.message);
        }
      } else {
        if (l.buy_num <= 1) {
          Toast(`不能再少了`);
          return false;
        }
        await getCartQuantity(l.cart_id, --l.buy_num);
      }
      this.getFindCartChcekData();
    },
    onSubmit() {
      if (this.checkResult?.length != 0) {
        if (this.cart_state) {
          this.$router.push({ name: "Buy", query: { cart_id: this.cart_id } });
        } else {
          Dialog.confirm({
            title: "删除",
            message: "确定删除宝贝吗？",
          })
            .then(async () => {
              this.getCartDel();
              this.$store.dispatch("doSetCartNum", "carnum");
            })
            .catch(() => {
              // on cancel
            });
        }
      } else {
        Toast("您还没有选宝贝哦~");
      }
    },
    async getCartList() {
      //获取数据
      let checkResult = await getCartList();
      this.cart_list = checkResult;
    },
    async getCartAdd(bl_id, goods_id, quantity) {
      //新增购物车
      let checkResult = await getCartAdd(bl_id, goods_id, quantity);
      this.cart_list = checkResult;
    },
    async getCartDel() {
      //删除购物车
      let cart_id = "";

      this.checkResult.map((item, index) => {
        cart_id += `${index == 0 ? "" : ","}${item}`;
      });

      await getCartDel(cart_id);
      // let l = JSON.parse(JSON.stringify(this.cart_list));
      this.checkResult.map((item, i) => {
        for (let index = 0; index < this.cart_list.length; index++) {
          let list = this.cart_list[index];
          if (list.cart_id == item) {
            this.cart_list.splice(index, 1);
          }
        }
      });
      this.$refs.checkboxGroup.toggleAll(false); //取消选择
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.Cart_Header {
  position: relative;
  padding: rem(30);
  // filter: blur(20px);
  h1 {
    margin-bottom: rem(10);
    display: flex;
    // align-items: center;
    b {
      font-size: rem(46);
      font-weight: bold;
      flex: 1;
    }
    > span {
      font-size: rem(30);
      margin-top: rem(8);
    }
  }
  p {
    font-size: rem(26);
    color: grey;
  }
  > span {
    position: absolute;
    top: rem(10);
    right: rem(10);
  }
}
.Cart_Header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-filter: blur(20px);
  filter: blur(20px);
}

.Cart_Ul {
  background: #fff;
  width: rem(720);
  margin: 0 auto rem(250);
  border-radius: rem(10);
  padding: rem(24);
  box-sizing: border-box;
  h2 {
    display: flex;
    align-items: center;
    b {
      font-size: rem(34);
      font-weight: bold;
      margin-left: rem(20);
      flex: 1;
    }
    span {
      font-size: rem(26);
    }
  }
  .Cart_image {
    width: rem(220);
    height: rem(220);
    border-radius: rem(30);
    display: block;
    margin-left: rem(28);
  }
  li {
    padding: rem(26) 0;
  }
  .Cart_product {
    display: flex;
    flex-direction: column;
    margin-left: rem(30);
    h3 {
      @include ell2;
      font-size: rem(28);
      font-weight: bold;
      margin-bottom: rem(16);
    }
    .sku {
      flex: 1;
      .sku_i {
        > i {
          flex: 1;
          display: inline-block;
          background: #f7f7f7;
          color: #7e7d7d;
          border-radius: rem(4);
          padding: rem(4) rem(6);
        }
      }

      .sku_tip {
        display: inline-block;
        color: #fb5839;
        padding: rem(4);
        font-size: rem(16);
        border: 1px solid #fb5839;
        border-radius: rem(6);
        margin: rem(20) 0 rem(10);
      }
    }

    .data {
      display: flex;
      .price {
        color: #fb5839;

        i {
          font-size: rem(16);
        }
        font-size: rem(30);
        font-weight: bold;
      }
    }
  }
  .action {
    justify-content: flex-end;
    // margin-right: rem(30);

    img {
      display: block;
      width: rem(44);
      height: rem(44);
    }
    b {
      color: #333;
      font-size: rem(30);
      margin: 0 rem(14);
    }
  }
}

.Cart_Fixed {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: rem(100);
  left: 0;
  height: rem(128);
  background: #fff;
  z-index: 99;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 0 rem(26);
  .check {
    margin-left: rem(16);
  }
  .data {
    text-align: right;

    span {
      font-size: rem(26);
    }
    > i {
      color: #df6325;
    }
    > b {
      color: #df6325;
      font-size: rem(40);
    }
  }

  .Cart_Submit {
    margin-left: rem(20);
    text-align: center;
    line-height: rem(80);
    color: #fff;
    font-size: rem(30);
    width: rem(200);
    height: rem(80);
    border-radius: rem(40);
    background: linear-gradient(to right, #ff851a, #f45e29);
  }
}
::v-deep .van-checkbox__label {
  font-size: rem(26);
}

.xs_tips {
  display: flex;
  // margin-left: rem(50);
  .xs_t-p {
    flex: 1;
    margin-bottom: rem(20);
    color: red;
  }
}
::v-deep {
  #time {
    margin-top: rem(30);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
