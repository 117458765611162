<template>
  <div id="time">
    <span class="tips">{{ msec ? "限时优惠活动已结束" : tips }}</span>
    <div class="solid" v-if="msec == false">
      <span v-if="day != 0">{{ day }}天</span><i v-if="day != 0"> : </i>
      <span>{{ hr }}时</span><i> : </i> <span>{{ min }}分</span><i> : </i>
      <span>{{ sec }}秒</span>
      <!-- {{ `${}天 ${hr}小时 ${min}分钟 ${sec}秒` }} -->
    </div>
  </div>
</template>
<script>
export default {
  name: "otime",
  props: {
    times: {},
    tips: {
      default: "距离结束还有",
      type: String,
    },
  },
  data: () => ({
    day: 0,
    hr: 0,
    min: 0,
    sec: 0,
    timer: null,
    msec: false,
  }),
  mounted: function() {
    this.countdown();
  },
  methods: {
    countdown: function() {
      const end = this.times;

      // const end = Date.parse(new Date('2020-12-03 00:00:00'))
      // console.log(end,this.times*1000)
      const now = Date.parse(new Date());
      const msec = end - now;
      if (msec < 0) {
        this.msec = true;
        clearTimeout(this.timer);
        return false;
      }
      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);
      this.day = day;
      this.hr = hr > 9 ? hr : "0" + hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      const that = this;
      this.timer = setTimeout(function() {
        that.countdown();
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#time {
  // width: rem(400);
}
.tips {
  color: #eb3570;
  // color: #eb3570; #ff4747
}
.solid {
  span {
    display: inline-block;
    width: rem(60);
    height: rem(46);
    line-height: rem(46);
    border-radius: rem(4);
    text-align: center;
    color: #fff;

    background: #eb3570;
    // background: #eb3570;  #ff4747
    i {
      color: #74595e;
    }
  }
}
</style>
